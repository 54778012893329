import axios from 'axios';
import { INVENTORY_LIST } from './types';
import { ROOT_URL } from '../root-url';

export const fetchInventories = (filter) => (dispatch) =>
  axios
    .post(ROOT_URL.collection('inventory'), {
      filter: {
        published: true,
        ...filter,
      },
    })
    .then(({ data }) => {
      return dispatch({
        type: INVENTORY_LIST,
        inventory: data.entries,
      });
    })
    .catch((e) => {
      return;
    });

export const fetchInventoriesTest = (filter) => {
  return axios
    .post(ROOT_URL.collection('inventory'), {
      filter: {
        published: true,
        ...filter,
      },
    })
    .then((res) => {
      return {
        inventory: res.data.entries,
      };
    });
};
