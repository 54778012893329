import { combineReducers } from 'redux';

import homeReducer from './home-reducer';
import inventoryReducer from './inventory-reducer';
import mrButtonReducer from './mrButton-reducer';
import wantedReducer from './wanted-reducer';

export const rootReducer = combineReducers({
  home: homeReducer,
  inventory: inventoryReducer,
  mrButton: mrButtonReducer,
  wanted: wantedReducer,
});
