import React, { Component } from 'react';

import axios from 'axios';
import { ROOT_URL, API_URL } from '../redux/root-url';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, HeroImg, Footer } from '../components/main';
import { Container, Heading } from '../components/layout';
import heroImg from '~/src/static/images/inventory.jpg';
import ReactMarkdown from 'react-markdown';

const List = (props) => (
  <ul className="list" style={{ marginBottom: '12px' }}>
    {props.children}
  </ul>
);
const Paragraph = (props) => (
  <p style={{ marginBottom: '12px' }}>{props.children}</p>
);

class Wanted extends Component {
  state = {
    wanted: null,
  };
  componentDidMount() {
    console.log('hi');
    axios.get(ROOT_URL.region('wanted')).then(({ data }) => {
      console.log(data);
      this.setState({ wanted: data });
    });
  }
  openPopup = (url) => (e) => {
    e.preventDefault();
    window.open(
      url,
      'popUpWindow',
      'left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes'
    );
  };

  renderHelper = () => {
    console.log(this.state.wanted);

    if (!this.state.wanted) return;
    return (
      <div className="financing">
        <div
          className="plus-block detail-box col-12"
          style={{ paddingTop: '28px' }}
        >
          {/* <h6 className="plus uppercase" style={{ marginBottom: '16px' }}>
            {this.state.wanted.title}
          </h6> */}
          <ReactMarkdown
            source={this.state.wanted.content}
            renderers={{
              list: List,
              paragraph: Paragraph,
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <MainWrapper>
        <Head title="Wanted | AVIONMAR" />
        <Nav />
        <HeroImg imgUrl={heroImg} className={'subpage'}>
          <h1>Wanted</h1>
        </HeroImg>

        <Container
          className="row flex-align-start justify-center"
          section="more-padding"
        >
          <div className="col-8">
            <Heading text="Wanted" className="primary" fill="#00A7E1" />
            {this.renderHelper()}
          </div>
        </Container>
        <Footer />
      </MainWrapper>
    );
  }
}
export default Wanted;
