import React from 'react';
import { Link } from 'react-router-dom';

const WantedButton = ({ text }) => (
  <div className="col-lg-4 col-6 inventory-small no-anim">
    <Link to="/wanted">
      <button
        className="mr-button no-hover row flex-align-center justify-center"
        style={{ fontSize: 'calc(1rem + 3vw)' }}
      >
        <span>{text}</span>
      </button>
    </Link>
  </div>
);
export default WantedButton;
